var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("Quality Grower")]),_c('v-card-subtitle',[_vm._v("This shows the average performance of each grower based off tomato grade quality.")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loadingExport)?_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.generateExport()}}},[_vm._v(" Generate ")]),(_vm.exportDownloadLink)?_c('a',{attrs:{"href":_vm.exportDownloadLink,"target":"_blank","download":"quality_grower.csv"}},[_vm._v("Download Export")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select start date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select end date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.calculate()}}},[_vm._v("Calculate")])],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e()],1)],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Table")]),_c('v-tab',[_vm._v("Chart")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:_vm.Table},[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.generateTableData().headers,"items":_vm.generateTableData().items,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"500px"}}),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.generateTableData().headers,"items":_vm.generateTableData().totals,"disable-pagination":"","hide-default-footer":"","fixed-header":""}})],1)],1)],1)],1)],1),_c('v-tab-item',{key:_vm.Chart},[_c('v-card',[_c('v-card-text',[_c('ApexChart',{attrs:{"type":"bar","options":{
                chart: {
                  id: 'QualityGrowerChart'
                },
              },"series":[{
                name: 'Defect Percentage',
                data: this.chartData
              }]}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }