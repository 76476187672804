<template>
  <v-container>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>Tons To Go</v-card-title>
              <v-card-text>

                <v-tabs
                  v-model="tab"
                >
                  <v-tab>Table</v-tab>
                  <v-tab>Chart</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    :key="Table"
                  >
                    <v-card>
                      <v-card-text>
                        <!-- <v-simple-table
                fixed-header
              height="500px"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Grower</th>
                        <th class="text-left">Cont Tons</th>
                        <th class="text-left">Measured Acres</th>
                        <th class="text-left">Delivered Tons</th>
                        <th class="text-left">Tons Left</th>
                        <th class="text-left">Estimated Tons</th>
                        <th class="text-left">Total</th>
                        <th class="text-left">% Available</th>
                        <th class="text-left">% Delivered</th>
                        <th class="text-left">Estimated Tons Per Acre</th>
                        <th class="text-left">% of Estimated Delivered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="calc in calcTotal"
                        :key="calc.grower.id"
                      >
                        <td><b>{{calc.grower.code}}</b></td>
                        <td>{{calc.deliveriesCalc.contractedTons | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.measuredAcres | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.deliveredTons | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.tonsLeft | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.estimatedTons | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.total | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.percentageAvailable | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.percentageDelivered | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.estimatedTonsPerAcre | number('0,0.00')}}</td>
                        <td>{{calc.deliveriesCalc.percentageOfEstimatedDelivered | number('0,0.00')}}</td>
                      </tr>
                      <tr
                        key="totals"
                      >
                        <td><b>TOTAL</b></td>
                        <td>{{calcTotalTotal.contractedTons | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.measuredAcres | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.deliveredTons | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.tonsLeft | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.estimatedTons | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.total | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.percentageAvailable | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.percentageDelivered | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.estimatedTonsPerAcre | number('0,0.00')}}</td>
                        <td>{{calcTotalTotal.percentageOfEstimatedDelivered | number('0,0.00')}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table> -->

                        <v-data-table
                          :headers="generateTableData().headers"
                          :items="generateTableData().items"
                          disable-pagination
                          hide-default-footer
                          fixed-header
                          height="120px"
                        ></v-data-table>

                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item
                    :key="Chart"
                  >
                    <v-card>
                      <v-card-text>
                        <ApexChart type="bar" :options="{
                          chart: {
                            id: 'TonsToGoChart'
                          },
                        }" :series="[{
                          name: 'Contracted',
                          data: this.chartData
                        }]">
                        </ApexChart>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>

                
              </v-card-text>
              <v-card-actions></v-card-actions>
            </v-card>
          </v-col>
        </v-row>

  </v-container>
</template>

<script>

import axios from '../../service/axios.config';
import numeral from 'numeral';

  export default {
    name: 'TonsToGo',
    components: {},
    data: function() {
      return {
        chartData: [],
        tab: null,
        currentSeason: {},
        growers: [],
        growerDeliveries: {},
        calcTotal: [],
        calcTotalTotal: {
          contractedTons: 0,
          measuredAcres: 0,
          deliveredTons: 0,
          tonsLeft: 0,
          estimatedTons: 0,
          total: 0,
          percentageAvailable: 0,
          percentageDelivered: 0,
          estimatedTonsPerAcre: 0,
          percentageOfEstimatedDelivered: 0,
        }
      }
    },
    methods: {
      loadData: async function(){

        console.log('ttg2:', this.currentUser.grower_id);

        const seasonRes = await axios.get("/season", {
          params: {
            active: true,
          }
        });

        // Get season and grower data
        this.currentSeason = seasonRes.data.seasons[0];
        this.growers = this.currentSeason.growers.map((grower) => {
          // filter active season data
          grower.active_seasonal_data = grower.grower_seasonal_data.filter((seasonalData) => {
            if(seasonalData.season_id == this.currentSeason.id){
              return seasonalData;
            }
          });

          // filter active season fields
          grower.active_seasonal_fields = grower.grower_fields.filter((field) => {
            if(field.season_id == this.currentSeason.id){
              return field;
            }
          });
          return grower;
        });
        
        if(this.currentUser.grower_id){
          this.growers = this.growers.filter((grower) => {
            if (grower.id == this.currentUser.grower_id){
              return true;
            }
            return false;
          });
        }
        
        // Special request just for deliveries
        const tonsToGoRes = await axios.get("/dashboard/tonstogo");

        // Sort deliveries into groups by grower
        tonsToGoRes.data.deliveries.forEach((delivery) => {
          if(!this.growerDeliveries[delivery.grower_id]){
            this.growerDeliveries[delivery.grower_id] = [];
          }
          this.growerDeliveries[delivery.grower_id] = [...this.growerDeliveries[delivery.grower_id], delivery];
        });

        // console.log(this.growers);
        // Calculate for each grower
        this.growers.forEach((grower) => {
          // console.log(this.growerDeliveries);
          const deliveriesForGrower = this.growerDeliveries[grower.id];

          const growerCalculations = this.calculateForGrower(grower, deliveriesForGrower);

          this.calcTotal = [...this.calcTotal, {
            grower: grower,
            deliveriesCalc: growerCalculations
          }].sort((a, b) => {
            const nameA = a.grower.code; // Convert names to uppercase for case-insensitive sorting
            const nameB = b.grower.code;

            if (nameA < nameB) {
              return -1; // a should come before b
            }
            if (nameA > nameB) {
              return 1; // b should come before a
            }
            return 0; // names are equal
          });
        });

        let tempChartData = [];
        this.calcTotal.forEach((growerCalc) => {
          tempChartData.push({
            x: growerCalc.grower.code,
            y: growerCalc.deliveriesCalc.contractedTons,
            goals: [
              {
                name: 'Delivered',
                value: growerCalc.deliveriesCalc.deliveredTons,
                strokeColor: '#775DD0'
              }
            ]
          });
        });
        this.chartData = tempChartData;

        this.calculateForGrowerTotals();
      },
      calculateForGrower: function(grower, deliveries){
        let calculations = {
          contractedTons: 0,
          measuredAcres: 0,
          deliveredTons: 0,
          tonsLeft: 0,
          estimatedTons: 0,
          total: 0,
          percentageAvailable: 100,
          percentageDelivered: 0,
          estimatedTonsPerAcre: 0,
          percentageOfEstimatedDelivered: 0,
        };

        // let growersActiveSeasonData = grower.active_seasonal_data.find((seasonalObj) => {
        //   return seasonalObj.id === this.currentSeason.id;
        // })

        // contractedTons
        calculations.contractedTons = Number(grower.active_seasonal_data[0].contracted_tons);

        // measuredAcres
        grower.active_seasonal_fields.forEach((field) => {
          calculations.measuredAcres += Number(field.acres);
        });

        // estimatedTons
        calculations.estimatedTons = Number(grower.active_seasonal_data[0].estimated_tons_per_acre);

        // estimatedTonsPerAcre
        if(grower.active_seasonal_fields.length > 0){

          let totalAcres = 0;

          for (const field of grower.active_seasonal_fields) {
            totalAcres += Number(field.acres);
          }

          calculations.estimatedTonsPerAcre = Number(calculations.estimatedTons) / Number(totalAcres);
        } else {
          calculations.estimatedTonsPerAcre = 0;
        }

        if(deliveries){
          // deliveredTons
          let deliveredTonsCalc = null;

          for(const delivery of deliveries){
            if(delivery.grades.length > 0){
              let deliveryTotalCullTear = delivery.grades[0].calculated_grade.mot.cullTear + delivery.grades[0].calculated_grade.grassGreen.cullTear + delivery.grades[0].calculated_grade.processingGreen.cullTear + delivery.grades[0].calculated_grade.processingBreakers.cullTear + delivery.grades[0].calculated_grade.otherDefects.cullTear + delivery.grades[0].calculated_grade.limitedUse.cullTear;
              // deliveredTonsCalc += Math.round((delivery.weight_in - delivery.weight_out) / 2000);
              deliveredTonsCalc += (((delivery.weight_in - delivery.weight_out) - (((delivery.weight_in - delivery.weight_out) / 100) * deliveryTotalCullTear)) / 2000)
            }
          }

          calculations.deliveredTons = deliveredTonsCalc;

          // tonsLeft
          // calculations.tonsLeft = Number(calculations.contractedTons) - Number(deliveredTonsCalc);
          calculations.tonsLeft = Number(grower.active_seasonal_data[0].estimated_tons_per_acre) - Number(deliveredTonsCalc);

          // total
          calculations.total = Number(deliveredTonsCalc) + Number(calculations.tonsLeft);

          // percentageAvailable
          calculations.percentageAvailable = (Number(calculations.total) / Number(grower.active_seasonal_data[0].contracted_tons)) * 100;

          // percentageDelivered
          calculations.percentageDelivered = ((Number(calculations.deliveredTons) / Number(calculations.contractedTons)) * 100);

          // percentageOfEstimatedDelivered
          // console.log(deliveredTonsCalc);
          // console.log(calculations.estimatedTons);

          if(Number(calculations.estimatedTons) > 0){
            calculations.percentageOfEstimatedDelivered = ((Number(deliveredTonsCalc) / Number(calculations.total)) * 100);
          } else {
            calculations.percentageOfEstimatedDelivered = 0;
          }
          
        }

        return calculations;
      },
      calculateForGrowerTotals: function(){
        
        for(const growerCalc of this.calcTotal){
          this.calcTotalTotal.contractedTons += Number(growerCalc.deliveriesCalc.contractedTons);
          this.calcTotalTotal.measuredAcres += Number(growerCalc.deliveriesCalc.measuredAcres);
          this.calcTotalTotal.deliveredTons += Number(growerCalc.deliveriesCalc.deliveredTons);

          if(growerCalc.deliveriesCalc.tonsLeft > 0){
            this.calcTotalTotal.tonsLeft += Number(growerCalc.deliveriesCalc.tonsLeft);
          }
          
          this.calcTotalTotal.estimatedTons += Number(growerCalc.deliveriesCalc.estimatedTons);
          this.calcTotalTotal.total += Number(growerCalc.deliveriesCalc.total);

          // if(growerCalc.deliveriesCalc.percentageAvailable > 0){
          //   this.calcTotalTotal.percentageAvailable += Number(growerCalc.deliveriesCalc.percentageAvailable);
          // }

          // this.calcTotalTotal.percentageDelivered += Number(growerCalc.deliveriesCalc.percentageDelivered);

          if(growerCalc.deliveriesCalc.estimatedTonsPerAcre > 0){
            this.calcTotalTotal.estimatedTonsPerAcre += Number(growerCalc.deliveriesCalc.estimatedTonsPerAcre);
          }
          
          this.calcTotalTotal.percentageOfEstimatedDelivered += Number(growerCalc.deliveriesCalc.percentageOfEstimatedDelivered);
        }

        this.calcTotalTotal.percentageDelivered = ((Number(this.calcTotalTotal.deliveredTons) / Number(this.calcTotalTotal.contractedTons)) * 100);
        this.calcTotalTotal.percentageAvailable = 100 - this.calcTotalTotal.percentageDelivered;
        this.calcTotalTotal.estimatedTonsPerAcre = this.calcTotalTotal.estimatedTonsPerAcre / this.growers.length;
        // this.calcTotalTotal.percentageOfEstimatedDelivered = ((Number(this.calcTotalTotal.deliveredTons) / Number(this.calcTotalTotal.estimatedTons) / this.growers.length) * 100);
        this.calcTotalTotal.percentageOfEstimatedDelivered = (this.calcTotalTotal.percentageOfEstimatedDelivered / this.growers.length);
      },
      generateTableData: function(){
        let headers = [{
          text: "Grower",
          value: "grower"
        },{
          text: "Cont Tons",
          value: "contTons"
        },{
          text: "Measured Acres",
          value: "measuredAcres"
        },{
          text: "Delivered Tons",
          value: "deliveredTons"
        },{
          text: "Tons Left",
          value: "tonsLeft"
        },{
          text: "Estimated Tons",
          value: "estimatedTons"
        },{
          text: "Total",
          value: "total"
        },{
          text: "% Available",
          value: "percAvailable"
        },{
          text: "% Delivered",
          value: "percDelivered"
        },{
          text: "Estimated Tons Per Acre",
          value: "estimatedTonsPerAcre"
        },{
          text: "% of Estimated Delivered",
          value: "percOfEstimatedDelivered"
        }];

        let items = [] // {with headers}

        for (let calc of this.calcTotal) {
          items = [...items, {
            grower: calc.grower.code,
            contTons: numeral(calc.deliveriesCalc.contractedTons).format('0,0.00'),
            measuredAcres: numeral(calc.deliveriesCalc.measuredAcres).format('0,0.00'),
            deliveredTons: numeral(calc.deliveriesCalc.deliveredTons).format('0,0.00'),
            tonsLeft: numeral(calc.deliveriesCalc.tonsLeft).format('0,0.00'),
            estimatedTons: numeral(calc.deliveriesCalc.estimatedTons).format('0,0.00'),
            total: numeral(calc.deliveriesCalc.total).format('0,0.00'),
            percAvailable: numeral(calc.deliveriesCalc.percentageAvailable).format('0,0.00'),
            percDelivered: numeral(calc.deliveriesCalc.percentageDelivered).format('0,0.00'),
            estimatedTonsPerAcre: numeral(calc.deliveriesCalc.estimatedTonsPerAcre).format('0,0.00'),
            percOfEstimatedDelivered: numeral(calc.deliveriesCalc.percentageOfEstimatedDelivered).format('0,0.00'),
          }]
        }

        return {
          headers: headers,
          items: items,
          totals: [
            {
              grower: `TOTAL`,
              contTons: numeral(this.calcTotalTotal.contractedTons).format('0,0.00'),
              measuredAcres: numeral(this.calcTotalTotal.measuredAcres).format('0,0.00'),
              deliveredTons: numeral(this.calcTotalTotal.deliveredTons).format('0,0.00'),
              tonsLeft: numeral(this.calcTotalTotal.tonsLeft).format('0,0.00'),
              estimatedTons: numeral(this.calcTotalTotal.estimatedTons).format('0,0.00'),
              total: numeral(this.calcTotalTotal.total).format('0,0.00'),
              percAvailable: numeral(this.calcTotalTotal.percentageAvailable).format('0,0.00'),
              percDelivered: numeral(this.calcTotalTotal.percentageDelivered).format('0,0.00'),
              estimatedTonsPerAcre: numeral(this.calcTotalTotal.estimatedTonsPerAcre).format('0,0.00'),
              percOfEstimatedDelivered: numeral(this.calcTotalTotal.percentageOfEstimatedDelivered ).format('0,0.00'),
            }
          ]
        }
      }
    },
    computed: {
      authenticated: function() {
        return this.$store.getters['AuthStore/authenticated'];
      },
      currentUser: function(){
        return this.$store.getters['AuthStore/user'];
      },
    },
    watch: {
    },
    mounted: function(){
      (async  () => {
        await this.loadData();
      })();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
