<template>
  <v-container>
    <v-card>
      <v-card-title>OPVG Export</v-card-title>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Select start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-btn
              color="primary"
              v-on:click="downloadOPVGExport()"
            >
              Generate
            </v-btn>

            <a v-if="CSVDownloadLink" :href="CSVDownloadLink" target="_blank" download="OPVG_export.csv">Download CSV</a>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import axios from '../../service/axios.config';
  import Papa from 'papaparse';

  export default {
    name: 'OPVGExport',
    components: {},
    data: function() {
      return {
        startMenu: false,
        startDate: null,
        growerSeasonalData: [],
        currentSeason: {},
        deliveries: [],
        CSVDownloadLink: null,
      }
    },
    methods: {
      loadData: function(){
        // console.log('loadData');

        axios.get('/season', {
          params: {
            active: true,
          }
        }).then((response) => {
          this.currentSeason = response.data.seasons[0];
        });

      },
      generateCSV: function(deliveries, growerSeasonalData){

        let sheet = [];

        for (const delivery of deliveries) {

          const deliveryGrowerData = growerSeasonalData.filter((seasonalGrowerData) => {
            if(seasonalGrowerData.grower_id == delivery.grower_id){
              return seasonalGrowerData;
            }
          })[0];

          const totalCullTare = Number(delivery.grades[0].calculated_grade.mot.cullTear) + Number(delivery.grades[0].calculated_grade.grassGreen.cullTear) + Number(delivery.grades[0].calculated_grade.processingGreen.cullTear) + Number(delivery.grades[0].calculated_grade.processingBreakers.cullTear) + Number(delivery.grades[0].calculated_grade.otherDefects.cullTear) + Number(delivery.grades[0].calculated_grade.limitedUse.cullTear);
          const netWeightDelivered = Number(delivery.weight_in) - Number(delivery.weight_out);
          const tarePenalty = netWeightDelivered * (totalCullTare / 100);
          const netPayableWeight = netWeightDelivered - tarePenalty;
          const pctOfContracted = netPayableWeight / (Number(deliveryGrowerData.contracted_tons) * 2000);

          const row = {
            processor: "Highbury Canco Corporation",
            gradeType: 'Original',
            grower: delivery.grower.name,
            certificate: delivery.grades[0].id,
            dateTime: delivery.grades[0].created_at,

            gradeOption: 7,
            colour: delivery.grades[0].agtron,
            colourlimit: 37,
            colorRejected: '',
            soluableSolids: delivery.grades[0].refractometer,
            variety: delivery.variety.code,

            motWeight: delivery.grades[0].calculated_grade.mot.lbs,
            motPct: delivery.grades[0].calculated_grade.mot.percentage,
            motLimit: this.currentSeason.grading_data.mot.rejection,
            motReject: delivery.grades[0].calculated_grade.mot.reject,
            motTare: delivery.grades[0].calculated_grade.mot.cullTear,

            grassGreenWeight: delivery.grades[0].calculated_grade.grassGreen.lbs,
            grassGreenPct: delivery.grades[0].calculated_grade.grassGreen.percentage,
            grassGreenLimit: this.currentSeason.grading_data.grassGreen.rejection,
            grassGreenReject: delivery.grades[0].calculated_grade.grassGreen.reject,
            grassGreenTare: delivery.grades[0].calculated_grade.grassGreen.cullTear,

            processingGreenWeight: delivery.grades[0].calculated_grade.processingGreen.lbs,
            processingGreenPct: delivery.grades[0].calculated_grade.processingGreen.percentage,
            processingGreenLimit: this.currentSeason.grading_data.processingGreen.rejection,
            processingGreenReject: delivery.grades[0].calculated_grade.processingGreen.reject,
            processingGreenTare: delivery.grades[0].calculated_grade.processingGreen.cullTear,

            processingBreakersWeight: delivery.grades[0].calculated_grade.processingBreakers.lbs,
            processingBreakersPct: delivery.grades[0].calculated_grade.processingBreakers.percentage,
            processingBreakersLimit: this.currentSeason.grading_data.processingBreakers.rejection,
            processingBreakersReject: delivery.grades[0].calculated_grade.processingBreakers.reject,
            processingBreakersTare: delivery.grades[0].calculated_grade.processingBreakers.cullTear,

            otherWeight: delivery.grades[0].calculated_grade.otherDefects.lbs,
            otherPct: delivery.grades[0].calculated_grade.otherDefects.percentage,
            otherLimit: this.currentSeason.grading_data.otherDefects.rejection,
            otherReject: delivery.grades[0].calculated_grade.otherDefects.reject,
            otherTare: delivery.grades[0].calculated_grade.otherDefects.cullTear,

            limitedUseWeight: delivery.grades[0].calculated_grade.limitedUse.lbs,
            limitedUsePct: delivery.grades[0].calculated_grade.limitedUse.percentage,
            limitedUseLimit: this.currentSeason.grading_data.limitedUse.rejection,
            limitedUseReject: delivery.grades[0].calculated_grade.limitedUse.reject,
            limitedUseTare: delivery.grades[0].calculated_grade.limitedUse.cullTear,

            totalCullTare: totalCullTare,
            loadStatus: 'ACCEPTED',
            incomingWeight: delivery.weight_in,
            outgoingWeight: delivery.weight_out,
            netWeightDelivered: netWeightDelivered,
            tarePenalty: tarePenalty,
            netPayableWeight: netPayableWeight,
            finalLoadStatus: "ACCEPTED",
            pctOfContracted: pctOfContracted,
          }

          sheet = [...sheet, row];
        }

        const csv = Papa.unparse(sheet);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        this.CSVDownloadLink = url;

        //console.log(sheet);
      },
      downloadOPVGExport: async function(){

        let [year, month, day] = this.startDate.split('-');
        let date = new Date(year, month-1, day, 0, 0, 0, 0);
        let startOfDay = date.toISOString();

        axios.get('/delivery', {
          params: {
            scale_date: startOfDay,
            scale_addDays: 1,
          }
        }).then((res) => {
          this.deliveries = res.data.deliveries;
          axios.get(`/season/${this.currentSeason.id}/growers/data`).then((response) => {
            this.growerSeasonalData = response.data.seasonal_grower_data;
            this.generateCSV(this.deliveries, this.growerSeasonalData);
          });
        });
      }
    },
    watch: {

    },
    mounted: function(){

      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      let now = new Date;
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      this.startDate = now.toISOString().substr(0, 10);
      this.endDate = now.addDays(1).toISOString().substr(0, 10);

      this.loadData();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
