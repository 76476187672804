import axios from '../../service/axios.config';
import Cookies from 'js-cookie';

export default {
	namespaced: true,
	state: {
		authenticated: Cookies.get('access_token') != undefined,
		token: Cookies.get('access_token'),
		user:
			Cookies.get('user') != undefined
				? JSON.parse(Cookies.get('user'))
				: undefined,
	},
	getters: {
		user(state) {
			return state.user;
		},
		token(state) {
			return state.token;
		},
		authenticated(state) {
			return state.authenticated;
		},
	},
	mutations: {
		setUser(state, data) {
			state.user = data;
		},
		setToken(state, data) {
			state.token = data;
		},
		setAuthenticated(state, data) {
			state.authenticated = data;
		},
	},
	actions: {
		async login({ commit }, payload) {
			try {
				const response = await axios.post('/auth/otp', {
					user_name: payload.user_name,
					password: payload.password,
          otp: payload.otp
				});

				if (response.status == 200) {
					await Cookies.set('access_token', response.data.access_token);
					await Cookies.set('user', JSON.stringify(response.data.user));

					await commit('setToken', response.data.access_token);
					await commit('setUser', response.data.user);
					await commit('setAuthenticated', true);

					return true;
				} else {
					return false;
				}
			} catch (error) {
				return false;
			}
		},
    async loginSave({ commit }, payload) {
      await Cookies.set('access_token', payload.access_token);
      await Cookies.set('user', JSON.stringify(payload.user));

      await commit('setToken', payload.access_token);
      await commit('setUser', payload.user);
      await commit('setAuthenticated', true);

      return true;
		},
		async logout({ commit }) {
			try {
				// axios.post('/auth/logout', {});

				Cookies.remove('access_token');
				Cookies.remove('user');

				commit('setToken', null);
				commit('setUser', null);
				commit('setAuthenticated', false);

				return true;
			} catch (error) {
				return false;
			}
		},
	},
};
