<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- <TestComponent/> -->
        <InventoryTable/>
        <ScheduledProcessed/>
        <YardLoad/>

        <TonsToGo/>
        <QualityGrower/>
        <RawTomato/>
        
        <OPVGExport/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import InventoryTable from './dashboardTables/InventoryTable.vue';
  import ScheduledProcessed from './dashboardTables/ScheduledProcessed.vue';
  import YardLoad from './dashboardTables/YardLoad.vue';
  import TonsToGo from './dashboardTables/TonsToGo.vue';
  import QualityGrower from './dashboardTables/QualityGrower.vue';
  import RawTomato from './dashboardTables/RawTomato.vue';
  import OPVGExport from './dashboardTables/OPVGExport.vue';
  // import TestComponent from './dashboardTables/TestComponent.vue';

  export default {
    name: 'DashBoard',
    components: {
      InventoryTable,
      ScheduledProcessed,
      YardLoad,
      TonsToGo,
      QualityGrower,
      RawTomato,
      OPVGExport,
      // TestComponent
    },
    data: function() {
      return {
      }
    },
    methods: {

    },
    watch: {

    },
    mounted: function() {

    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

