<template>
  <v-container>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>Certificates</v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Certificate #
                        </th>
                        <th class="text-left">
                          Delivery ID
                        </th>
                        <th class="text-left">
                          Variety
                        </th>
                        <th class="text-left">
                          Scheduled
                        </th>
                        <th class="text-left">
                          Regrade by
                        </th>
                        <th class="text-left">
                          Regrade of
                        </th>
                        <th class="text-left">
                          Fixed by
                        </th>
                        <th class="text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="grade in certificates"
                        :key="grade.id"
                      >
                        <td>{{ grade.id }}</td>
                        <td>{{ grade.delivery_id }}</td>
                        <td>{{ grade.variety ? grade.variety.code : '---' }}</td>
                        <td>{{ new Date(grade.updated_at).toDateString() }}</td>
                        <td>{{ grade.previous_grade_id || '-' }}</td>
                        <td>{{ grade.regrade_id || '-' }}</td>
                        <td>{{ grade.fix_user ? grade.fix_user.name : '-' }}</td>
                        <td>
                          <v-btn color="primary" v-on:click="printCertificate(grade.delivery_id)">Certificate</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions></v-card-actions>
            </v-card>
          </v-col>
        </v-row>

    <v-overlay
      :z-index="11"
      :absolute="true"
      class="d-flex align-start"
      :value="viewCertificate"
      width="100%"
      
    >
    <CertificateViewer :deliveryId="selectedDeliveryId" />
    <v-btn color="error" v-on:click="closeCert()">Close</v-btn>
  </v-overlay>

  </v-container>
</template>

<script>

import axios from '../../service/axios.config';
import CertificateViewer from '@/components/CertificateViewer.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Certificate',
    components: {
      CertificateViewer
    },
    data: function() {
      return {
        certificates: [],
        selectedDeliveryId: null,
        viewCertificate: false
      }
    },
    methods: {
      loadData: async function(){
        const growerCertificates = await axios.get("/grade", {
          params: {
            active: true,
            grower_id: this.currentUser.grower_id
          }
        });
        this.certificates = growerCertificates.data.grades;
      },
      printCertificate: function(deliveryId){
        this.selectedDeliveryId = deliveryId;
        this.viewCertificate = true;
      },
      closeCert: function(){
        this.selectedDeliveryId = null;
        this.viewCertificate = false;
      },
    },
    computed: {
      authenticated: function() {
        return this.$store.getters['AuthStore/authenticated'];
      },
      currentUser: function(){
        return this.$store.getters['AuthStore/user'];
      },
    },
    watch: {
    },
    mounted: function(){
      (async  () => {
        await this.loadData();
      })();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
