var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Tons To Go")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loadingExport)?_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.generateExport()}}},[_vm._v(" Generate ")]),(_vm.exportDownloadLink)?_c('a',{attrs:{"href":_vm.exportDownloadLink,"target":"_blank","download":"tons_to_go.csv"}},[_vm._v("Download Export")]):_vm._e()],1)],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Table")]),_c('v-tab',[_vm._v("Chart")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:_vm.Table},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.generateTableData().headers,"items":_vm.generateTableData().items,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"500px"}}),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.generateTableData().headers,"items":_vm.generateTableData().totals,"disable-pagination":"","hide-default-footer":"","fixed-header":""}})],1)],1)],1),_c('v-tab-item',{key:_vm.Chart},[_c('v-card',[_c('v-card-text',[_c('ApexChart',{attrs:{"type":"bar","options":{
                        chart: {
                          id: 'TonsToGoChart'
                        },
                      },"series":[{
                        name: 'Contracted',
                        data: this.chartData
                      }]}})],1)],1)],1)],1)],1),_c('v-card-actions')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }