<template>
    <v-row style="width: 900px">
      <v-col cols="12">
        <v-card width="100%">
          <v-card-text>
            <div id="printableCert" class="cert">
              <div class="container">
                <div class="row">
                  <div class="col">

                    <div class="row">
                      <div class="d-flex justify-content-center">
                        <h5 class="d-flex justify-content-center">Highbury Canco Corporation Tomato Grading Certificate</h5>
                      </div>
                    </div>

                    <div class="row">
                      <h5 class="d-flex justify-content-end">Trailer: {{delivery.trailer_number}}</h5>
                    </div>

                    <div class="row">
                      <h5 class="d-flex justify-content-end">Master Certificate No: {{latestCertificate.id}}</h5>
                    </div>

                    <div class="row">
                      <h5 class="d-flex justify-content-end">Seasonal Certificate No: {{latestCertificate.seasonal_certificate_id}}</h5>
                    </div>

                    <div class="row">
                      <h5>Previous Grade (MASTER ID): {{ latestCertificate.previous_grade_id ? latestCertificate.previous_grade_id : 'NONE' }}</h5>
                    </div>

                    <div class="row">
                      <h5>Active Grade (MASTER ID): {{ latestCertificate.regrade_id ? latestCertificate.regrade_id : 'NONE' }}</h5>
                    </div>

                    <div class="row">
                      <h5>{{ latestCertificate.special_grade ? latestCertificate.special_grade : '' }}</h5>
                    </div>

                    <div class="row border-top border-bottom">
                      <div class="col-7">
                        <div>Grower: <b>{{delivery.grower ? delivery.grower.name : ''}}</b> ({{delivery.grower.code}})</div>
                        <div>Address: {{delivery.grower.address ? delivery.grower.address.street_address : ''}}</div>
                        <div>         {{delivery.grower.address ? delivery.grower.address.city.name : ''}}, {{delivery.grower.address ? delivery.grower.address.province.name : ""}}</div>
                        <div>Field: {{delivery.field ? delivery.field.name : ''}}</div>
                      </div>
                      <div class="col-2">
                        <div>Scheduled at:</div>
                        <div>Received at:</div>
                        <div>Graded at:</div>
                        <div>Scaled Out at:</div>
                      </div>
                      <div class="col-3">
                        <div>{{delivery.start ? new Date(delivery.start + ' UTC').toLocaleString() : '---'}}</div>
                        <div>{{delivery.arrived_at ? new Date(delivery.arrived_at).toLocaleString() : '---'}}</div>
                        <div>{{latestCertificate.created_at ? new Date(latestCertificate.created_at).toLocaleString() : '---'}}</div>
                        <div>{{delivery.scaled_at ? new Date(delivery.scaled_at).toLocaleString() : '---'}}</div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col d-flex justify-content-start">
                        <div><b>Grade Readings</b></div>
                      </div>
                      <div class="col d-flex justify-content-end">
                        <!-- <div><b>Variety:</b> {{delivery.variety.code}}</div> -->
                      </div>
                    </div>

                    <br/>

                    <div class="row">

                      <table style="width:100%">
                        <tr>
                          <th>Category</th>
                          <th>Lbs</th>
                          <th>%</th>
                          <th>Reject Level</th>
                          <th>Tare Applicable</th>
                          <th>Cull Tare</th>
                          <th>Reject</th>
                        </tr>
                        <tr>
                          <td>M.O.T</td>
                          <td>{{Number(gradeCertCalc.mot.lbs).toFixed(2)}}</td>
                          <td>{{gradeCertCalc.mot.percentage}}</td>
                          <td>Over {{ season.grading_data.mot.rejection }}%</td>
                          <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_mot">{{ comp.from }}-{{ comp.to }}%x{{ comp.tear }}</div></td>
                          <td>{{gradeCertCalc.mot.cullTear}}%</td>
                          <td>{{gradeCertCalc.mot.reject ? '*' : ''}}</td>
                        </tr>
                        <tr>
                          <td>Grass Green</td>
                          <td>{{Number(gradeCertCalc.grassGreen.lbs).toFixed(2)}}</td>
                          <td>{{gradeCertCalc.grassGreen.percentage}}</td>
                          <td>Over {{ season.grading_data.grassGreen.rejection }}%</td>
                          <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_grassGreen">{{ comp.from }}-{{ comp.to }}%x{{ comp.tear }}</div></td>
                          <td>{{gradeCertCalc.grassGreen.cullTear}}%</td>
                          <td>{{gradeCertCalc.grassGreen.reject ? '*' : ''}}</td>
                        </tr>
                        <tr>
                          <td>Processing Green</td>
                          <td>{{Number(gradeCertCalc.processingGreen.lbs).toFixed(2)}}</td>
                          <td>{{gradeCertCalc.processingGreen.percentage}}</td>
                          <td>Over {{ season.grading_data.processingGreen.rejection }}%</td>
                          <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_processingGreen">{{ comp.from }}-{{ comp.to }}%x{{ comp.tear }}</div></td>
                          <td>{{gradeCertCalc.processingGreen.cullTear}}%</td>
                          <td>{{gradeCertCalc.processingGreen.reject ? '*' : ''}}</td>
                        </tr>
                        <tr>
                          <td>Processing Breakers</td>
                          <td>{{Number(gradeCertCalc.processingBreakers.lbs).toFixed(2)}}</td>
                          <td>{{gradeCertCalc.processingBreakers.percentage}}</td>
                          <td>Over {{ season.grading_data.processingBreakers.rejection }}%</td>
                          <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_processingBreakers">{{ comp.from }}-{{ comp.to }}%x{{ comp.tear }}</div></td>
                          <td>{{gradeCertCalc.processingBreakers.cullTear}}%</td>
                          <td>{{gradeCertCalc.processingBreakers.reject ? '*' : ''}}</td>
                        </tr>
                        <tr>
                          <td>Other Defects</td>
                          <td>{{Number(gradeCertCalc.otherDefects.lbs).toFixed(2)}}</td>
                          <td>{{gradeCertCalc.otherDefects.percentage}}</td>
                          <td>Over {{ season.grading_data.otherDefects.rejection }}%</td>
                          <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_otherDefects">{{ comp.from }}-{{ comp.to }}%x{{ comp.tear }}</div></td>
                          <td>{{gradeCertCalc.otherDefects.cullTear}}%</td>
                          <td>{{gradeCertCalc.otherDefects.reject ? '*' : ''}}</td>
                        </tr>
                        <tr>
                          <td>Limited Use</td>
                          <td>{{Number(gradeCertCalc.limitedUse.lbs).toFixed(2)}}</td>
                          <td>{{gradeCertCalc.limitedUse.percentage}}</td>
                          <td>Over {{ season.grading_data.limitedUse.rejection }}%</td>
                          <td><div class="compounding_li" v-bind:key="index" v-for="(comp, index) in compounding_limitedUse">{{ comp.from }}-{{ comp.to }}%x{{ comp.tear }}</div></td>
                          <td>{{gradeCertCalc.limitedUse.cullTear}}%</td>
                          <td>{{gradeCertCalc.limitedUse.reject ? '*' : ''}}</td>
                        </tr>
                      </table>
                    </div>

                    <br/><br/>

                    <div class="row">
                      <div class="col-3">
                        <div>Clean</div>
                      </div>

                      <div class="col-1">
                        <div>{{Number(gradeCertCalc.totalSampleWeight - totalDefectLbs).toFixed(2)}}</div>
                      </div>

                      <div class="col-1">
                        <div>{{100 - totalDefectPercentage}}</div>
                      </div>

                      <div class="col-2">
                        <div></div>
                      </div>

                      <div class="col-2">
                        <div></div>
                      </div>

                      <div class="col-2">
                        <div></div>
                      </div>

                      <div class="col-1">
                        <div></div>
                      </div>
                    </div>

                    <hr/>

                    <div class="row">
                      <div class="col-3">
                        <div>Total</div>
                      </div>

                      <div class="col-1">
                        <div>{{Number(gradeCertCalc.totalSampleWeight).toFixed(2)}}</div>
                      </div>

                      <div class="col-1">
                        <div>100</div>
                      </div>

                      <div class="col-2">
                        <div></div>
                      </div>

                      <div class="col-2">
                        <div></div>
                      </div>

                      <div class="col-2">
                        <div>{{totalCullTear}}%</div>
                      </div>

                      <div class="col-1">
                        <div></div>
                      </div>
                    </div>

                    <br/><br/>

                    <div class="row">
                      <div class="col-3">
                        <div>AGTRON</div>
                        <div>Refractometer</div>
                        <div>Category Price</div>
                      </div>

                      <div class="col-1">
                        <div></div>
                      </div>

                      <div class="col-2">
                        <div>{{Number(gradeCertCalc.agtron).toFixed(2)}}</div>
                        <div>{{Number(gradeCertCalc.refractometer).toFixed(2)}}</div>
                        <div>${{season ? (season.tomato_price / 100) : '-'}}</div>
                      </div>

                      <div class="col-2">
                        <div>Over 37%</div>
                      </div>

                      <div class="col-2">
                        <div></div>
                      </div>

                      <!-- 
                      <div class="col-2">
                        <div></div>
                      </div> -->

                      <div class="col-1">
                        <div></div>
                      </div>
                    </div>

                    <br/>
                    <div class="col">
                        <br/><br/>
                        <div>________________________</div>
                        <div>Grader</div>
                        <div>{{ latestCertificate.grader ? latestCertificate.grader.name : '-' }}</div>
                      </div>

                    <br/><br/>

                    <div class="row border-top border-bottom">
                      <br/><br/>
                      <div class="col">
                        <div><b>Load Status</b></div>
                        <div>Rejected: {{ delivery.rejected ? ' * ' : "___" }} </div>
                        <div>Accepted: {{ delivery.sentToScale && !rejectable ? ' * ' : "___" }}</div>
                        <div>Rejectable but Accepted: {{ delivery.sentToScale && rejectable ? ' * ' : "___" }}</div>
                      </div>

                      <div class="col">
                        <br/><br/>
                        <div>________________________</div>
                        <div>Receiver</div>
                        <div>{{ delivery.receiver ? delivery.receiver.name : ' ' }}</div>
                      </div>
                      <br/><br/>
                    </div>

                    <div class="row border-top border-bottom">
                      <br/><br/>

                      <div class="col-3">
                        <div>Gross lbs</div>
                        <div>Vehicle tare lbs</div>
                        <div>Delivered lbs</div>
                        <div>Cull tare lbs</div>
                        <div>Net payable lbs</div>
                        <div>Net payable tons</div>
                        <div>Tons Scheduled</div>
                        <div>Tons Delivered </div>
                        <div>Tons Over/Under Schedule</div>
                      </div>

                      <div class="col-3">
                        <div>{{delivery.weight_in | number('0,0')}}</div>
                        <div>{{delivery.weight_out | number('0,0')}}</div>
                        <div>{{(delivery.weight_in - delivery.weight_out) | number('0,0')}}</div>
                        <div>{{(((delivery.weight_in - delivery.weight_out) / 100) * totalCullTear) | number('0,0')}}</div>

                        <!-- <div>{{((delivery.weight_in - delivery.weight_out) - (((delivery.weight_in - delivery.weight_out) / 100) * totalCullTear)) | number('0,0')}}</div> -->
                        <div>{{ Math.round(((delivery.weight_in - delivery.weight_out) - (((delivery.weight_in - delivery.weight_out) / 100) * totalCullTear))) | number('0,0') }}</div>
                        <div>{{ (Math.round(((delivery.weight_in - delivery.weight_out) - (((delivery.weight_in - delivery.weight_out) / 100) * totalCullTear))) / 2000) | number('0,0.0000')}}</div>

                        <div>{{delivery.grower.load_size}}</div>
                        <div>{{((delivery.weight_in - delivery.weight_out) / 2000).toFixed(2)}}</div>
                        <div>{{(((delivery.weight_in - delivery.weight_out) / 2000) - delivery.grower.load_size).toFixed(2)}}</div>
                      </div>

                      <div class="col">
                        <br/><br/><br/>
                        <div>________________________</div>
                        <div>Scale Clerk</div>
                        <div>{{ delivery.scaler ? delivery.scaler.name : ' ' }}</div>
                      </div>

                      <br/><br/>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-btn color="primary" v-on:click="printCertificate()">Print Cert</v-btn>
      </v-col>
    </v-row>
</template>

<script>

  import axios from '../service/axios.config';
  // import grading from '../util/grading';


  export default {
    name: 'CertificateViewer',
    components: {
    
    },
    props: {
      deliveryId: Number,
      certificateId: Number
    },
    data: function() {
      return {
        delivery: {},
        latestCertificate: {},
        gradeCertCalc: null,
        totalDefectLbs: null,
        totalDefectPercentage: null,
        totalCullTear: null,
        season: null,
        rejectable: false
      }
    },
    methods: {
      printCertificate: function(){
        this.$htmlToPaper("printableCert");
      },
    },
    computed: {
      compounding_mot: function() {
        return this.season.grading_data.mot.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_grassGreen: function() {
        return this.season.grading_data.grassGreen.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_processingGreen: function() {
        return this.season.grading_data.processingGreen.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_processingBreakers: function() {
        return this.season.grading_data.processingBreakers.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_otherDefects: function() {
        return this.season.grading_data.otherDefects.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
      compounding_limitedUse: function() {
        return this.season.grading_data.limitedUse.compounding.filter((comp) => {
          if(comp.from != 0 || comp.to != 0 || comp.tear != 0){
            return comp;
          }
        });
      },
    },
    watch: {

    },
    mounted: function(){
      axios.get(`/delivery/${this.deliveryId}`).then((response) => { 
        axios.get('/season').then((response) => {
          this.season = response.data.seasons.filter((season) => {
            if(season.active){
              return season;
            }
          })[0];

          // console.log(this.season);
        });

        this.delivery = response.data.delivery;

        if(this.certificateId){
          this.latestCertificate = response.data.delivery.grades.filter((grade) => {
            return grade.id === this.certificateId;
          })[0];
        } else {
          this.latestCertificate = response.data.delivery.grades.sort((a, b) => {
            return b.id - a.id;
          })[0];
        }

        this.rejectable = this.latestCertificate.calculated_grade.grassGreen.reject || this.latestCertificate.calculated_grade.limitedUse.reject || this.latestCertificate.calculated_grade.mot.reject || this.latestCertificate.calculated_grade.otherDefects.reject || this.latestCertificate.calculated_grade.processingBreakers.reject || this.latestCertificate.calculated_grade.processingGreen.reject;

        this.gradeCertCalc = this.latestCertificate.calculated_grade;

        this.totalCullTear = this.gradeCertCalc.mot.cullTear + this.gradeCertCalc.grassGreen.cullTear + this.gradeCertCalc.processingGreen.cullTear + this.gradeCertCalc.processingBreakers.cullTear + this.gradeCertCalc.otherDefects.cullTear + this.gradeCertCalc.limitedUse.cullTear;
        this.totalDefectPercentage = this.gradeCertCalc.mot.percentage + this.gradeCertCalc.grassGreen.percentage + this.gradeCertCalc.processingGreen.percentage + this.gradeCertCalc.processingBreakers.percentage + this.gradeCertCalc.otherDefects.percentage + this.gradeCertCalc.limitedUse.percentage;
        this.totalDefectLbs = Number(this.gradeCertCalc.mot.lbs) + Number(this.gradeCertCalc.grassGreen.lbs) + Number(this.gradeCertCalc.processingGreen.lbs) + Number(this.gradeCertCalc.processingBreakers.lbs) + Number(this.gradeCertCalc.otherDefects.lbs) + Number(this.gradeCertCalc.limitedUse.lbs);
      });
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>

<style scoped>
  .cert{
    padding: 25px;
  }

  .compounding_li{
    font-size: 0.7em;
    list-style: none;
    line-height: 110%;
  }

  th {
    text-align: left;
  }
</style>
