var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Tons To Go")]),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Table")]),_c('v-tab',[_vm._v("Chart")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:_vm.Table},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.generateTableData().headers,"items":_vm.generateTableData().items,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"120px"}})],1)],1)],1),_c('v-tab-item',{key:_vm.Chart},[_c('v-card',[_c('v-card-text',[_c('ApexChart',{attrs:{"type":"bar","options":{
                        chart: {
                          id: 'TonsToGoChart'
                        },
                      },"series":[{
                        name: 'Contracted',
                        data: this.chartData
                      }]}})],1)],1)],1)],1)],1),_c('v-card-actions')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }