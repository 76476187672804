var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("Scheduled Processed")]),_c('v-card-subtitle',[_vm._v("This displays the difference between the scheduled tonnage and the actual tonnage used per hour.")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select start date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1)],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Table")]),_c('v-tab',[_vm._v("Chart")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:_vm.Table},[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.generateTableData().mainData.headers,"items":_vm.generateTableData().mainData.items,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"500px"}}),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.generateTableData().footerData.headers,"items":_vm.generateTableData().footerData.items,"disable-pagination":"","hide-default-footer":"","fixed-header":""}})],1)],1)],1)],1)],1),_c('v-tab-item',{key:_vm.Chart},[_c('v-card',[_c('v-card-text',[_c('ApexChart',{attrs:{"type":"line","options":{
                chart: {
                  id: 'ScheduledProcessedChart'
                },
                xaxis: this.chart.xaxis
              },"series":_vm.chart.series}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }