<template>
  <v-container>
    <v-card>
      <v-card-title>Quality Grower</v-card-title>
      <v-card-subtitle>This shows the average performance of each grower based off tomato grade quality.</v-card-subtitle>
      <v-card-text>

        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Select start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="endMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Select end date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" v-on:click="calculate()">Calculate</v-btn>
          </v-col>
          <v-col cols="3">
            <v-progress-circular
              v-if="loading"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-tabs
          v-model="tab"
        >
          <v-tab>Table</v-tab>
          <v-tab>Chart</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="Table"
          >
            <v-card>
              <v-card-text>
                <v-row class="text-center">
          <v-col cols="12">
            <!-- <v-simple-table
              fixed-header
              height="500px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Grower
                    </th>
                    <th class="text-left">
                      Contracted Tons
                    </th>
                    <th class="text-left">
                      Measured Acres
                    </th>
                    <th class="text-left">
                      Loads
                    </th>
                    <th class="text-left">
                      Payable Tons
                    </th>

                    <th class="text-left">
                      M.O.T
                    </th>
                    <th class="text-left">
                      Grass Green
                    </th>
                    <th class="text-left">
                      Processing Green
                    </th>
                    <th class="text-left">
                      Processing Breakers
                    </th>
                    <th class="text-left">
                      Other Defects
                    </th>
                    <th class="text-left">
                      Limited Use
                    </th>
                    <th class="text-left">
                      Agtron
                    </th>
                    <th class="text-left">
                      Refractometer
                    </th>
                    <th class="text-left">
                      Percentage Delivered
                    </th>
                    <th class="text-left">
                      Tons per Acre
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="grade in growerGrade"
                    :key="grade.id"
                  >
                    <td><b>{{ grade.code }}</b></td>
                    <td>{{ grade.contracted_tons | number('0,0.00') }}</td>
                    <td>{{ grade.measured_acres | number('0,0.00')}}</td>
                    <td>{{ grade.loads }}</td>
                    <td>{{ grade.payable_tons | number('0,0.00')}}</td>
                    <td>{{ ((grade.defect.mot.total / grade.defect.mot.count) * 100) | number('0,0.00')}}%</td>
                    <td>{{ ((grade.defect.grass_green.total / grade.defect.grass_green.count) * 100) | number('0,0.00')}}%</td>
                    <td>{{ ((grade.defect.processing_green.total / grade.defect.processing_green.count) * 100) | number('0,0.00')}}%</td>
                    <td>{{ ((grade.defect.processing_breakers.total / grade.defect.processing_breakers.count) * 100) | number('0,0.00')}}%</td>
                    <td>{{ ((grade.defect.other.total / grade.defect.other.count) * 100) | number('0,0.00')}}%</td>
                    <td>{{ ((grade.defect.limited_use.total / grade.defect.limited_use.count) * 100) | number('0,0.00')}}%</td>
                    <td>{{ (grade.defect.agtron.total / grade.defect.agtron.count) | number('0,0.00')}}</td>
                    <td>{{ (grade.defect.refractometer.total / grade.defect.refractometer.count) | number('0,0.00')}}</td>
                    <td>{{ ((grade.payable_tons / grade.contracted_tons) * 100) | number('0,0.00') }}%</td>
                    <td>{{ ( grade.payable_tons / grade.total_acres) | number('0,0.00') }}</td>
                  </tr>
                  <tr>
                    <td><b>TOTAL</b></td>
                    <td>{{ totals.contracted_tons | number('0,0.00') }}</td>
                    <td>{{ totals.measured_acres | number('0,0.00')}}</td>
                    <td>{{ totals.loads }}</td>
                    <td>{{ totals.payable_tons | number('0,0.00')}}</td>

                    <td>{{ (totals.defect.mot.total / totals.defect.mot.count) * 100 | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.grass_green.total / totals.defect.grass_green.count) * 100  | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.processing_green.total / totals.defect.processing_green.count) * 100  | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.processing_breakers.total / totals.defect.processing_breakers.count) * 100  | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.other.total / totals.defect.other.count) * 100  | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.limited_use.total / totals.defect.limited_use.count) * 100  | number('0,0.00') }}%</td>
                    <td>{{ (totals.defect.agtron.total / totals.defect.agtron.count)  | number('0,0.00') }}</td>
                    <td>{{ (totals.defect.refractometer.total / totals.defect.refractometer.count)  | number('0,0.00') }}</td>

                    <td>{{ (totals.percentage_delivered / growers.length) | number('0,0.00') }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->

            <v-data-table
              :headers="generateTableData().headers"
              :items="generateTableData().items"
              disable-pagination
              hide-default-footer
              fixed-header
              height="120px"
            ></v-data-table>

          </v-col>
        </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item
            :key="Chart"
          >
            <v-card>
              <v-card-text>
                <ApexChart type="bar" :options="{
                  chart: {
                    id: 'QualityGrowerChart'
                  },
                }" :series="[{
                  name: 'Defect Percentage',
                  data: this.chartData
                }]">
                </ApexChart>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import axios from '../../service/axios.config';
import numeral from 'numeral';

  export default {
    name: 'QualityGrower',
    components: {},
    data: function() {
      return {
        chartData: [],
        tab: null,
        loading: false,
        currentSeason: {},
        startMenu: false,
        startDate: null,
        endMenu: false,
        endDate: null,
        additionalDays: 1,
        deliveries: null,
        growers: [],
        growerGrade: [],
        totals: {}
      }
    },
    methods: {
      loadData: function(){

        let now = new Date;
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);

        // Format start date
        this.startDate = now.toISOString().substr(0, 10);
        this.endDate = now.addDays(10).toISOString().substr(0, 10);

        axios.get("/season", {
          params: {
            active: true,
          }
        }).then((response) => {
          this.currentSeason = response.data.seasons[0];

          this.growers = this.currentSeason.growers.map((grower) => {
            // filter active season data
            grower.active_seasonal_data = grower.grower_seasonal_data.filter((seasonalData) => {
              if(seasonalData.season_id == this.currentSeason.id){
                return seasonalData;
              }
            });

            // filter active season fields
            grower.active_seasonal_fields = grower.grower_fields.filter((field) => {
              if(field.season_id == this.currentSeason.id){
                return field;
              }
            });
            return grower;
          });

          if(this.currentUser.grower_id){
            this.growers = this.growers.filter((grower) => {
              if (grower.id == this.currentUser.grower_id){
                return true;
              }
              return false;
            });
          }

          this.calculate();
        });
      },
      calculate: function(){

        this.loading = true;

        let [yearS, monthS, dayS] = this.startDate.split('-');
        let queryStartDate = new Date(yearS, monthS-1, dayS, 0, 0, 0, 0);

        let [yearE, monthE, dayE] = this.endDate.split('-');
        let queryEndDate = new Date(yearE, monthE-1, dayE, 0, 0, 0, 0);
          
        let Difference_In_Days = (queryEndDate.getTime() - queryStartDate.getTime()) / (1000 * 3600 * 24);

        // Query all deliveries
        axios.get('/delivery', {
          params: {
            scale_date: queryStartDate,
            scale_addDays: Difference_In_Days,
          }
        }).then((response) => {

          // Format deliveries
          this.deliveries = response.data.deliveries.map((el) => {
            el.start = new Date(el.start + ' UTC');
            el.end = new Date(el.end + ' UTC');
            el.name = el.grower.code;
            el.timed = true;
            return el;
          });

          let growerGrade = {};

          this.growers.forEach((grower) => {

            let measuredAcres = 0;
            for (const field of grower.grower_fields) {
              if(field.season_id == this.currentSeason.id){
                measuredAcres += Number(field.acres);
              }
            }

            growerGrade[grower.id] = {
              id: grower.id,
              code: grower.code,
              contracted_tons: Number(grower.active_seasonal_data[0]?.contracted_tons),
              measured_acres: Number(measuredAcres),
              loads: 0,
              payable_tons: 0,
              percentage_delivered: 0,
              tons_per_acre: Number(grower.active_seasonal_data[0]?.estimated_tons_per_acre),
              total_acres: Number(measuredAcres),
              defect: {
                mot: {
                  total: 0,
                  count: 0
                },
                grass_green: {
                  total: 0,
                  count: 0
                },
                processing_green: {
                  total: 0,
                  count: 0
                },
                processing_breakers: {
                  total: 0,
                  count: 0
                },
                other: {
                  total: 0,
                  count: 0
                },
                limited_use: {
                  total: 0,
                  count: 0
                },
                agtron: {
                  total: 0,
                  count: 0
                },
                refractometer: {
                  total: 0,
                  count: 0
                },
              }
            }
          });

          // Calculate
          this.deliveries.forEach((delivery) => {
            let validGrade = delivery.grades[delivery.grades.length - 1];
            // console.log(validGrade);

            if(validGrade && delivery.variety_id != null){

              growerGrade[delivery.grower.id].loads += 1;

              // growerGrade[delivery.grower.id].payable_tons += (Number(delivery.weight_in) - Number(delivery.weight_out)) / 2000;

              let tempGrowerPayableTons = 0;

              if(delivery.grades.length > 0){
                let deliveryTotalCullTear = delivery.grades[0].calculated_grade.mot.cullTear + delivery.grades[0].calculated_grade.grassGreen.cullTear + delivery.grades[0].calculated_grade.processingGreen.cullTear + delivery.grades[0].calculated_grade.processingBreakers.cullTear + delivery.grades[0].calculated_grade.otherDefects.cullTear + delivery.grades[0].calculated_grade.limitedUse.cullTear;
                tempGrowerPayableTons += (((delivery.weight_in - delivery.weight_out) - (((delivery.weight_in - delivery.weight_out) / 100) * deliveryTotalCullTear)) / 2000)
              }

              growerGrade[delivery.grower.id].payable_tons += tempGrowerPayableTons;

              growerGrade[delivery.grower.id].defect.mot.total += (Number(validGrade.defect_mot) / Number(validGrade.total_sample_weight));
              growerGrade[delivery.grower.id].defect.mot.count += 1;

              growerGrade[delivery.grower.id].defect.grass_green.total += (Number(validGrade.defect_grass_green) / Number(validGrade.total_sample_weight));
              growerGrade[delivery.grower.id].defect.grass_green.count += 1;

              growerGrade[delivery.grower.id].defect.processing_green.total += (Number(validGrade.defect_processing_green) / Number(validGrade.total_sample_weight));
              growerGrade[delivery.grower.id].defect.processing_green.count += 1;

              growerGrade[delivery.grower.id].defect.processing_breakers.total += (Number(validGrade.defect_processing_breakers) / Number(validGrade.total_sample_weight));
              growerGrade[delivery.grower.id].defect.processing_breakers.count += 1;

              growerGrade[delivery.grower.id].defect.other.total += (Number(validGrade.defect_other) / Number(validGrade.total_sample_weight));
              growerGrade[delivery.grower.id].defect.other.count += 1;

              growerGrade[delivery.grower.id].defect.limited_use.total += (Number(validGrade.defect_limited_use) / Number(validGrade.total_sample_weight));
              growerGrade[delivery.grower.id].defect.limited_use.count += 1;

              growerGrade[delivery.grower.id].defect.agtron.total += Number(validGrade.agtron);
              growerGrade[delivery.grower.id].defect.agtron.count += 1;

              growerGrade[delivery.grower.id].defect.refractometer.total += Number(validGrade.refractometer);
              growerGrade[delivery.grower.id].defect.refractometer.count += 1;
            }
          });

          // Totals
          let totals = {
            contracted_tons: 0,
            measured_acres: 0,
            loads: 0,
            payable_tons: 0,
            percentage_delivered: 0,
            tons_per_acre: 0,
            defect: {
              mot: {
                total: 0,
                count: 0
              },
              grass_green: {
                total: 0,
                count: 0
              },
              processing_green: {
                total: 0,
                count: 0
              },
              processing_breakers: {
                total: 0,
                count: 0
              },
              other: {
                total: 0,
                count: 0
              },
              limited_use: {
                total: 0,
                count: 0
              },
              agtron: {
                total: 0,
                count: 0
              },
              refractometer: {
                total: 0,
                count: 0
              },
            }
          }

          for (const key in growerGrade) {
            const gradeCalc = growerGrade[key];
            // console.log(gradeCalc);

            totals.contracted_tons += Number(gradeCalc.contracted_tons);
            totals.measured_acres += Number(gradeCalc.measured_acres);
            totals.loads += Number(gradeCalc.loads);
            totals.payable_tons += Number(gradeCalc.payable_tons);
            totals.percentage_delivered += Number(gradeCalc.percentage_delivered);
            totals.tons_per_acre += Number(gradeCalc.tons_per_acre);

            totals.defect.mot.total += Number(gradeCalc.defect.mot.total);
            totals.defect.mot.count += Number(gradeCalc.defect.mot.count);

            totals.defect.grass_green.total += Number(gradeCalc.defect.grass_green.total);
            totals.defect.grass_green.count += Number(gradeCalc.defect.grass_green.count);

            totals.defect.processing_green.total += Number(gradeCalc.defect.processing_green.total);
            totals.defect.processing_green.count += Number(gradeCalc.defect.processing_green.count);

            totals.defect.processing_breakers.total += Number(gradeCalc.defect.processing_breakers.total);
            totals.defect.processing_breakers.count += Number(gradeCalc.defect.processing_breakers.count);

            totals.defect.other.total += Number(gradeCalc.defect.other.total);
            totals.defect.other.count += Number(gradeCalc.defect.other.count);

            totals.defect.limited_use.total += Number(gradeCalc.defect.limited_use.total);
            totals.defect.limited_use.count += Number(gradeCalc.defect.limited_use.count);

            totals.defect.agtron.total += Number(gradeCalc.defect.agtron.total);
            totals.defect.agtron.count += Number(gradeCalc.defect.agtron.count);

            totals.defect.refractometer.total += Number(gradeCalc.defect.refractometer.total);
            totals.defect.refractometer.count += Number(gradeCalc.defect.refractometer.count);

            // console.log(gradeCalc);
          }

          // console.log(totals);
          this.totals = totals;

          this.growerGrade = Object.values(growerGrade).sort((a, b) => {
            const nameA = a.code; // Convert names to uppercase for case-insensitive sorting
            const nameB = b.code;

            if (nameA < nameB) {
              return -1; // a should come before b
            }
            if (nameA > nameB) {
              return 1; // b should come before a
            }
            return 0; // names are equal
          });

          let tempChartData = [];
          this.growerGrade.forEach((growerGradeItem) => {

            let totalDefectPercentageMOT = (growerGradeItem.defect.mot.total / growerGradeItem.defect.mot.count) * 100;
            let totalDefectPercentageGG = (growerGradeItem.defect.grass_green.total / growerGradeItem.defect.grass_green.count) * 100;
            let totalDefectPercentagePG = (growerGradeItem.defect.processing_green.total / growerGradeItem.defect.processing_green.count) * 100;
            let totalDefectPercentagePB = (growerGradeItem.defect.processing_breakers.total / growerGradeItem.defect.processing_breakers.count) * 100;
            let totalDefectPercentageOD = (growerGradeItem.defect.other.total / growerGradeItem.defect.other.count) * 100;
            let totalDefectPercentageLU = (growerGradeItem.defect.limited_use.total / growerGradeItem.defect.limited_use.count) * 100;

            tempChartData.push({
              x: growerGradeItem.code,
              y: (totalDefectPercentageMOT + totalDefectPercentageGG + totalDefectPercentagePG + totalDefectPercentagePB + totalDefectPercentageOD + totalDefectPercentageLU).toFixed(2)
            });
          });

          this.chartData = tempChartData;

          this.loading = false;

        });
      },
      generateTableData: function(){
        let headers = [{
          text: "Grower",
          value: "grower"
        },{
          text: "Contracted Tons",
          value: "contractedTons"
        },{
          text: "Measured Acres",
          value: "measuredAcres"
        },{
          text: "Loads",
          value: "loads"
        },{
          text: "Payable Tons",
          value: "payableTons"
        },{
          text: "M.O.T",
          value: "mot"
        },{
          text: "Grass Green",
          value: "grassGreen"
        },{
          text: "Processing Green",
          value: "processingGreen"
        },{
          text: "Processing Breakers",
          value: "processingBreakers"
        },{
          text: "Other Defects",
          value: "otherDefects"
        },{
          text: "Limited Use",
          value: "limitedUse"
        },{
          text: "Agtron",
          value: "agtron"
        },{
          text: "Refractometer",
          value: "refractometer"
        },{
          text: "Percentage Delivered",
          value: "percentageDelivered"
        },{
          text: "Tons per Acre",
          value: "tonsPerAcre"
        },];

        let items = [] // {with headers}

        for (let grade of this.growerGrade) {
          items = [...items, {
            grower: grade.code,
            contTons: numeral(grade.contracted_tons).format('0,0.00'),
            measuredAcres: numeral(grade.measured_acres).format('0,0.00'),
            loads: numeral(grade.loads).format('0,0.00'),
            payableTons: numeral(grade.payable_tons).format('0,0.00'),
            mot: numeral(((grade.defect.mot.total / grade.defect.mot.count) * 100)).format('0,0.00'),
            grassGreen: numeral(((grade.defect.grass_green.total / grade.defect.grass_green.count) * 100)).format('0,0.00'),
            processingGreen: numeral(((grade.defect.processing_green.total / grade.defect.processing_green.count) * 100)).format('0,0.00'),
            processingBreakers: numeral(((grade.defect.processing_breakers.total / grade.defect.processing_breakers.count) * 100)).format('0,0.00'),
            otherDefects: numeral(((grade.defect.other.total / grade.defect.other.count) * 100)).format('0,0.00'),
            limitedUse: numeral(((grade.defect.limited_use.total / grade.defect.limited_use.count) * 100)).format('0,0.00'),
            agtron: numeral((grade.defect.agtron.total / grade.defect.agtron.count)).format('0,0.00'),
            refractometer: numeral((grade.defect.refractometer.total / grade.defect.refractometer.count)).format('0,0.00'),
            percentageDelivered: numeral(((grade.payable_tons / grade.contracted_tons) * 100)).format('0,0.00'),
            tonsPerAcre: numeral(( grade.payable_tons / grade.total_acres)).format('0,0.00'),
          }]
        }

        return {
          headers: headers,
          items: items,
          totals: [
            {
              grower: `TOTAL`,
              contTons: numeral(this.totals.contracted_tons).format('0,0.00'),
              measuredAcres: numeral(this.totals.measured_acres).format('0,0.00'),
              loads: numeral(this.totals.loads).format('0,0.00'),
              payableTons: numeral(this.totals.payable_tons).format('0,0.00'),
              mot: numeral((this.totals.defect.mot.total / this.totals.defect.mot.count) * 100).format('0,0.00'),
              grassGreen: numeral((this.totals.defect.grass_green.total / this.totals.defect.grass_green.count) * 100).format('0,0.00'),
              processingGreen: numeral((this.totals.defect.processing_green.total / this.totals.defect.processing_green.count) * 100).format('0,0.00'),
              processingBreakers: numeral((this.totals.defect.processing_breakers.total / this.totals.defect.processing_breakers.count) * 100 ).format('0,0.00'),
              otherDefects: numeral((this.totals.defect.other.total / this.totals.defect.other.count) * 100).format('0,0.00'),
              limitedUse: numeral((this.totals.defect.limited_use.total / this.totals.defect.limited_use.count) * 100).format('0,0.00'),
              agtron: numeral((this.totals.defect.agtron.total / this.totals.defect.agtron.count)).format('0,0.00'),
              refractometer: numeral((this.totals.defect.refractometer.total / this.totals.defect.refractometer.count)).format('0,0.00'),
              percentageDelivered: numeral((this.totals.percentage_delivered / this.growers.length)).format('0,0.00'),
              tonsPerAcre: null,
            }
          ]
        }
      }
    },
    computed: {
      authenticated: function() {
        return this.$store.getters['AuthStore/authenticated'];
      },
      currentUser: function(){
        return this.$store.getters['AuthStore/user'];
      },
    },
    watch: {
      // startDate: function(newValue){
      //   // when startDate is updated run load data with the new value
      //   this.loadData(newValue, this.additionalDays);
      // },
      // additionalDays: function(newValue){
      //   // when startDate is updated run load data with the new value
      //   this.loadData(this.startDate, newValue);
      // }
    },
    mounted: function(){

      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      this.loadData();
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
