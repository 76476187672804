<template>
  <v-container>
    <v-card>
      <v-card-title>Inventory</v-card-title>
      <v-card-subtitle>This section displays the tonnage available at different stages of the check in process.</v-card-subtitle>
      <v-card-text>

        <v-checkbox v-model="includeTransfer" label="Include transfer loads"></v-checkbox>

        <v-tabs
          v-model="tab"
        >
          <v-tab>Table</v-tab>
          <v-tab>Chart</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="Table"
          >
            <v-card>
              <v-card-text>
                <v-row class="text-center">
                  <v-col cols="4">
                    <h3>Checked In</h3>
                    <h2>{{tonnageCheckedIn}}</h2>
                  </v-col>

                  <v-col cols="4">
                    <h3>Total</h3>
                    <h2>{{tonnageTotal}}</h2>
                  </v-col>

                  <v-col cols="4">
                    <h3>Late</h3>
                    <h2>{{tonnageLate}}</h2>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="settings.inventory_upper"
                      type="number"
                      label="Upper limit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="settings.inventory_lower"
                      type="number"
                      label="Lower limit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <v-btn color="primary" v-on:click="updateInventoryLimits()">Save</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item
            :key="Chart"
          >
            <v-row>
              <v-col cols="6">
                <ApexChart type="donut" :options="{
                  chart: {
                    id: 'InventoryChart'
                  },
                  labels: ['Checked In', 'Total', 'Late']
                }" :series="[tonnageCheckedIn, tonnageTotal, tonnageLate]">
                </ApexChart>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>

        

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import axios from '../../service/axios.config';

  export default {
    name: 'InventoryTable',
    components: {},
    data: function() {
      return {
        deliveries: null,
        startDate: null,
        startMenu: false,
        endDate: null,
        endMenu: false,
        additionalDays: 1,

        tonnageCheckedIn: 0,
        tonnageTotal: 0,
        tonnageLate: 0,

        includeTransfer: true,
        settings: {},
        tab: null,
      }
    },
    methods: {
      loadData: function(){

        // Query all deliveries
        axios.get('/delivery', {
          params: {
            active: true,
            scaled: false,
            rejected: false
          }
        }).then((response) => {
          this.deliveries = response.data.deliveries;

          // reset tonnage
          let tonnageCheckedIn = 0;
          let tonnageTotal = 0;
          let tonnageLate = 0;

          // Calculate values
          for(const delivery of response.data.deliveries){
            if(delivery.active && delivery.arrived && delivery.grades.length == 0){ // add to checkedIn
              tonnageCheckedIn += Number(delivery.grower.load_size);
            } else if (delivery.active && delivery.arrived && delivery.grades.length > 0){
              tonnageTotal += Number(delivery.grower.load_size);
            } else if(delivery.active && !delivery.arrived && new Date() > new Date(delivery.start)){
              tonnageLate += Number(delivery.grower.load_size);
            }
          }

          // Reset calculations
          this.tonnageCheckedIn = tonnageCheckedIn;
          this.tonnageTotal = tonnageTotal;
          this.tonnageLate = tonnageLate;
        });

        axios.get('/setting').then((response) => {
          const settings = {};
          for(const setting of response.data.settings){
            settings[setting.name] = setting.value;
          }
          this.settings = settings;
        });
      },
      updateInventoryLimits: function() {

        // Update upper
        axios.patch(`/setting/inventory_upper`, {
          value: Number(this.settings.inventory_upper)
        }).then(() => {
        });

        // Update lower
        axios.patch(`/setting/inventory_lower`, {
          value: Number(this.settings.inventory_lower)
        }).then(() => {
        });
      }
    },
    watch: {
    },
    mounted: function(){
      // Load data from start date
      this.loadData();

    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
