<template>
  <v-container>
    <v-card>
      <v-card-title>Scheduled Processed</v-card-title>
      <v-card-subtitle>This displays the difference between the scheduled tonnage and the actual tonnage used per hour.</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Select start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-tabs
          v-model="tab"
        >
          <v-tab>Table</v-tab>
          <v-tab>Chart</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="Table"
          >
            <v-card>
              <v-card-text>
                <v-row class="text-center">
          <v-col cols="12">
            <v-data-table
              :headers="generateTableData().mainData.headers"
              :items="generateTableData().mainData.items"
              disable-pagination
              hide-default-footer
              fixed-header
              height="500px"
            ></v-data-table>

            <br>

            <v-data-table
              :headers="generateTableData().footerData.headers"
              :items="generateTableData().footerData.items"
              disable-pagination
              hide-default-footer
              fixed-header
            ></v-data-table>
          </v-col>
        </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item
            :key="Chart"
          >
            <v-card>
              <v-card-text>
                <ApexChart type="line" :options="{
                  chart: {
                    id: 'ScheduledProcessedChart'
                  },
                  xaxis: this.chart.xaxis
                }" :series="chart.series"></ApexChart>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import axios from '../../service/axios.config';
import numeral from 'numeral';
// import axios from '../../service/axios.config';
import decimalShiftRound from '@/util/decimalShiftRound';

  export default {
    name: 'ScheduledProcessed',
    components: {},
    data: function() {
      return {
        tab: null,
        chart: {
          xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
          },
          series:[
            {
              name: 'Tons Scheduled',
              data: [30, 40, 45, 50, 49, 60, 70, 91]
            },
            {
              name: 'Tons Processed',
              data: [30, 40, 45, 50, 49, 60, 70, 91]
            },
            {
              name: 'Average Tons Processed',
              data: [30, 40, 45, 50, 49, 60, 70, 91]
            },
          ]
        },
        startMenu: false,
        startDate: null,
        additionalDays: 1,
        deliveries: null,
        deliveredHourly: {},
        totals: {
          toDay: {
            scheduled: 0,
            processed: 0,
            wait: 0
          },
          toHour: {
            scheduled: 0,
            processed: 0,
            wait: 0
          }
        }
      }
    },
    methods: {
      decimalShiftRound: function(val, factor){
        return decimalShiftRound(val, factor);
      },
      toHoursAndMinutes: function (totalMinutes) {
        const minutes = Number(totalMinutes) % 60;
        const hours = Math.floor(Number(totalMinutes) / 60);

        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
      },
      loadData: function(startDate, addDays){
        // Passed start date formatting for query
        let [year, month, day] = startDate.split('-');
        let queryStartDate = new Date(year, month-1, day, 0, 0, 0, 0);

        console.log(addDays);

        axios.get('/delivery', {
          params: {
            // scale_date: queryStartDate,
            // scale_addDays: addDays,
            date: queryStartDate
          }
        }).then((response) => {
          this.deliveries = response.data.deliveries.map((el) => {
            el.start = new Date(el.start + ' UTC');
            el.end = new Date(el.end + ' UTC');
            el.name = el.grower.code;
            el.timed = true;
            return el;
          });

          let deliveredHourly = {}

          for (let index = 0; index < 24; index++) {
            deliveredHourly[index] = {
              hour: index,
              scheduled: 0,
              processed: 0,
              cumulative_scheduled: 0,
              cumulative_processed: 0,
              processedVscheduled: 0,
              wait: 0,
            }
          }

          // tally scheduled vs processed
          this.deliveries.forEach((delivery) => {
            let deliveryHour = delivery.start.getHours();

            deliveredHourly[deliveryHour].scheduled += decimalShiftRound(Number(delivery.grower.load_size));

            if(delivery.weight_out != null){
              deliveredHourly[deliveryHour].processed += (decimalShiftRound(delivery.weight_in) - decimalShiftRound(delivery.weight_out)) / 2000;
            }

            if(delivery.grades.length > 0){
              if(delivery.grades[0].arrived_at && delivery.grades[0].scaled_at){
                deliveredHourly[deliveryHour].wait += Math.floor(Math.abs(new Date(delivery.grades[0].scaled_at) - new Date(delivery.grades[0].arrived_at)) / (1000 * 60));
              }
            }
          });

          // Calculations
          let cumulative_scheduled = 0;
          let cumulative_processed = 0;

          for (const key in deliveredHourly) {
            cumulative_scheduled += decimalShiftRound(deliveredHourly[key].scheduled);
            cumulative_processed += decimalShiftRound(deliveredHourly[key].processed);

            deliveredHourly[key].cumulative_scheduled = cumulative_scheduled;
            deliveredHourly[key].cumulative_processed = cumulative_processed;

            deliveredHourly[key].processedVscheduled = decimalShiftRound(Number(cumulative_processed)) - decimalShiftRound(Number(cumulative_scheduled));
          }

          this.deliveredHourly = Object.values(deliveredHourly);

          let totals = {
            toDay: {
              scheduled: 0,
              processed: 0,
              wait: 0
            },
            toHour: {
              scheduled: 0,
              processed: 0,
              wait: 0
            }
          }

          const currentHour = new Date().getHours();

          for (const deliveredHourlyInstance of this.deliveredHourly) {
            // day
            totals.toDay.scheduled += decimalShiftRound(deliveredHourlyInstance.scheduled);
            totals.toDay.processed += decimalShiftRound(deliveredHourlyInstance.processed);
            totals.toDay.wait += decimalShiftRound(deliveredHourlyInstance.wait);

            //hours
            if(deliveredHourlyInstance.hour <= currentHour){
              totals.toHour.scheduled += decimalShiftRound(deliveredHourlyInstance.scheduled);
              totals.toHour.processed += decimalShiftRound(deliveredHourlyInstance.processed);
              totals.toHour.wait += decimalShiftRound(deliveredHourlyInstance.wait);
            }
          }

          this.totals = totals;

          // ChartData
          this.chart.xaxis.categories = [];
          let tempSeriesTonsScheduled = [];
          let tempSeriesTonsProcessed = [];
          let tempSeriesTonsProcessedAverage = [];
          Object.values(deliveredHourly).forEach((deliveryHour) => {
            this.chart.xaxis.categories.push(deliveryHour.hour);
            tempSeriesTonsScheduled.push(deliveryHour.scheduled.toFixed(2));
            tempSeriesTonsProcessed.push(deliveryHour.processed.toFixed(2));
            tempSeriesTonsProcessedAverage.push((totals.toDay.processed / 24).toFixed(2));
          });

          this.chart.series = [
            {
              name: 'Tons Scheduled',
              data: tempSeriesTonsScheduled
            },
            {
              name: 'Tons Processed',
              data: tempSeriesTonsProcessed
            },
            {
              name: 'Average Tons Processed',
              data: tempSeriesTonsProcessedAverage
            },            
          ];
        });
      },
      generateTableData: function(){
        let headers = [{
          text: "Hour",
          value: "hour"
        },{
          text: "Tons Scheduled",
          value: "tonsScheduled"
        },{
          text: "Tons Processed",
          value: "tonsProcessed"
        },{
          text: "Cumulative Scheduled",
          value: "cumulativeScheduled"
        },{
          text: "Cumulative Processed",
          value: "cumulativeProcessed"
        },{
          text: "Processed - Scheduled",
          value: "processedScheduled"
        },{
          text: "Waiting Times",
          value: "waitingTimes"
        }];

        let items = [] // {with headers}

        for (let deliveredHour of this.deliveredHourly) {
          items = [...items, {
            hour: deliveredHour.hour,
            tonsScheduled: numeral(deliveredHour.scheduled).format('0,0.00'),
            tonsProcessed: numeral(deliveredHour.processed).format('0,0.00'),
            cumulativeScheduled: numeral(deliveredHour.cumulative_scheduled).format('0,0.00'),
            cumulativeProcessed: numeral(deliveredHour.cumulative_processed).format('0,0.00'),
            processedScheduled: numeral(deliveredHour.processedVscheduled).format('0,0.00'),
            waitingTimes: this.toHoursAndMinutes(deliveredHour.wait)
          }]
        }

        return {
          mainData: {
            headers: headers,
            items: items
          },
          footerData: {
            headers: headers,
            items: [
              {
                hour: `Average to hour (${new Date().getHours()})`,
                tonsScheduled: numeral(Number(this.totals.toHour.scheduled) / new Date().getHours()).format('0,0.00'),
                tonsProcessed: numeral(Number(this.totals.toHour.processed) / new Date().getHours()).format('0,0.00'),
                cumulativeScheduled: null,
                cumulativeProcessed: null,
                processedScheduled: null,
                waitingTimes: numeral(Number(this.totals.toHour.wait) / new Date().getHours()).format('0,0.00')
              },{
                hour: `Average for day`,
                tonsScheduled: numeral(Number(this.totals.toDay.scheduled) / 24).format('0,0.00'),
                tonsProcessed: numeral(Number(this.totals.toDay.processed) / 24).format('0,0.00'),
                cumulativeScheduled: null,
                cumulativeProcessed: null,
                processedScheduled: null,
                waitingTimes: numeral(Number(this.totals.toDay.wait) / 24).format('0,0.00')
              }
            ]
          }
        }
      }
    },
    watch: {
      startDate: function(newValue){
        // when startDate is updated run load data with the new value
        this.loadData(newValue, this.additionalDays);
      },
      additionalDays: function(newValue){
        // when startDate is updated run load data with the new value
        this.loadData(this.startDate, newValue);
      }
    },
    mounted: function(){
      let now = new Date;
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      // Format start date
      this.startDate = now.toISOString().substr(0, 10);

      // Load data from start date
      this.loadData(this.startDate, this.additionalDays);
    },
    created: () => {

    },
    destroyed: () => {

    }
  }
</script>
